<template>
      <div>
        <transition name="modal">
          <div class="modal-mask">
              <div class="modal-wrapper">
                <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-body bg-dark" >
                          <slot name="title"></slot>
                          <div class="progress" >
                            <div class="progress-bar progress-bar-striped" role="progressbar" :style="'width:'+progress+'%;'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </transition>
    </div>
</template>
<script>
export default {
  props : {
    progress : {
      type : Number,
      default : 0
    }
  }
}
</script>
<style>
.progress{
  height: 1.3rem;
}
</style>
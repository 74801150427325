<template>
    <div class="row">
        <div class="col-12 contentProperties" v-if="show">             
            <div class="row"  >
                <div class="col-2 d-flex justify-content-center">
                    <div class="rounded-circle step-circle bg-secondary" :class="{ 'bg-success': this.status_donwload }">
                        <i class="fas fa-calculator m-3 font20 text-white"></i>
                    </div>
                </div>
                <div class="col-10 pl-0 d-flex align-items-center accordion-header justify-content-between" >
                    <div>
                        <span class="font-weight-bold accordion-header-title">{{$t("DESCARGADO")}}</span>
                        <div  class="font12 text-capitalize" v-if="data.donwload">{{data.donwload.user.name}} <br> {{data.donwload.created_at | moment("HH:MM DD/MM/YYYY") }}</div>                       
                    </div>
                    <div class="mr-3 checkStep">
                         <base-checkbox @input="onSaveStatus('status_donwload',1)" v-model="status_donwload"/>
                    </div>
                </div>
                <div class="col-1 border-rigth border-step border-secondary " :class="{'border-success' : this.status_donwload}"> </div>
                 <div class="col-11  step-content accordion-content"></div>
            </div>
            <div class="row ">
                <div class="col-2 d-flex justify-content-center">
                    <div class="bg-secondary rounded-circle step-circle" :class="{ 'bg-success': this.status_conf }">
                        <i class="fas fa-file-signature m-3 font20 text-white"></i>
                    </div>
                </div>
                <div class="col-10 pl-0 d-flex align-items-center accordion-header justify-content-between">
                    <div>
                        <span class="font-weight-bold accordion-header-title">{{$t("CONFORME")}}</span>
                        <div  class="font12 text-capitalize" v-if="data.conformidad"> {{data.conformidad.user.name}}<br> {{data.conformidad.created_at | moment("HH:MM DD/MM/YYYY")}}</div>                       
                    </div>
                    <div class="mr-3 checkStep">
                         <base-checkbox @input="onSaveStatus('status_conf',2)" v-model="status_conf"/>
                    </div>
                </div>
                <div class="col-1 border-rigth border-step border-secondary " :class="{'border-success' : this.status_conf}"> </div>
                 <div class="col-11  step-content accordion-content"></div>
            </div>
            <div class="row" >
                <div class="col-2 d-flex justify-content-center">
                    <div class="rounded-circle step-circle bg-secondary"  :class="{ 'bg-success': this.status_rev }">
                        <i class="fas fa-clipboard m-3 font20 text-white"></i>
                    </div>
                </div>
                <div class="col-10 pl-0 d-flex align-items-center accordion-header justify-content-between" >
                    <div>
                        <span class="font-weight-bold accordion-header-title">{{$t("REVISADO")}}</span>
                        <div class="font12 text-capitalize" v-if="data.revisado">{{data.revisado.user.name}}<br> {{data.revisado.created_at | moment("HH:MM DD/MM/YYYY")}}</div>                       
                    </div>
                    <div class="mr-3 checkStep">
                         <base-checkbox @input="onSaveStatus('status_rev',3)" :disabled="! inArray([1,2])" v-model="status_rev" />
                    </div>
                </div>
                <div class="col-1 border-rigth border-step border-secondary " :class="{'border-success' : this.status_rev}"> </div>
                <div class="col-11  step-content accordion-content"></div>
            </div>
            <div class="row mb-4" >
                <div class="col-2 d-flex justify-content-center">
                    <div class="rounded-circle step-circle bg-secondary"  :class="{ 'bg-success': this.status_la }">
                        <i class="fas fa-folder m-3 font20 text-white"></i>
                    </div>
                </div>
                <div class="col-10 pl-0 d-flex align-items-center accordion-header justify-content-between">
                    <div>
                        <span class="font-weight-bold accordion-header-title"> {{$t("L/A")}}</span>
                        <div class="font12 text-capitalize" v-if="data.archivado"> {{data.archivado.user.name}}<br> {{data.archivado.created_at | moment("HH:MM DD/MM/YYYY")}}</div>                       
                    </div>
                    <div class="mr-3 checkStep">
                        <base-checkbox @input="onSaveStatus('status_la',4)" :disabled="!inArray([1,2,3])"  v-model="status_la" />
                    </div>
                </div>
            </div>           
        </div>

    </div>
</template>

<script>
import BaseCheckbox from './Inputs/BaseCheckbox.vue';

export default {
    components: { 
      BaseCheckbox 
    },
    data(){
        return {
            expand : false,
            status_rev : false,
            status_conf : false,
            status_donwload : false,
            status_la   : false,
            all_status : [],
            data    : {},
            expiration_date : new Date(),
            taxes_document  : null,
            show : true,
            history : []
        }
    },
    props : {
        document : Object,
    },
    created(){
        this.onCheckHistory();
    },

    methods : {
        eventClick(){
            this.expand = !this.expand;
        },
        onSaveStatus(value,status_id){
            let self = this;
            if(this[value])
                this.showConfirm("confirmChangeStatus",() => {
                    self.onChangeStatus(status_id);
                },
                () => {
                    self[value] = false;
                });
            else{
                  this.showConfirm("msg_delete_status",() => {
                    self.onDeleteStatus(status_id);
                },
                () => {
                    self[value] = true;
                });
            }          
        },
        onCheckHistory(){
            this.$store.dispatch('isLoadingOn');  
            this.$axios("document/status?where-document_id="+this.document.id)
            .then((response) => {
              this.history = response.data;
              this.refresh();
              this.$store.dispatch('isLoadingOff');           
            })

        },
        refresh(){
            this.show = false;
            this.all_status = [];
            this.data = {}
            this.history.forEach((item) => {
                this.all_status.push(item.status_id)
                switch(item.status_id){
                    case 1:
                        this.status_donwload = true;
                        this.data.donwload = item;
                        break;
                    case 2:
                        this.status_conf = true;
                        this.data.conformidad = item;
                        break;
                    case 3:
                        this.status_rev = true;
                        this.data.revisado = item;
                        break;
                    case 4:             
                        this.data.archivado = item;
                        this.status_la = true;
                        break;
                }
            })
            this.show = true;
        },
        onChangeStatus(status){
            this.$store.dispatch('isLoadingOn');  
            let data = {
                'document_id' : this.document.id,
                'status_id'    : status
            }
            this.$axios.post("/document/status", data)
            .then(() => {
                this.onCheckHistory();
                this.$store.dispatch('isLoadingOff');  
            })
            .catch(() => {
                this.$store.dispatch('isLoadingOff');  
            })
        },
        showConfirm(Msg_confirm,sucessCallback, errorCallback){
            this.$dialog.confirm({
                title:  this.$t("confirmDocument"),
                body:   this.$t(Msg_confirm),
                okText: this.$t("Aceptar"),
                cancelText: this.$t("Cancelar"),
           }).then(() =>{
               sucessCallback();
           })
           .catch(() => {
               errorCallback();           
           })
        },
        inArray(keys){
            let validation = true;
            for(let row in keys){
                if(!this.all_status.includes(keys[row]))
                    validation = false;                
            }       
            return validation;
        },
        onDeleteStatus(status){
            let data = {
                'status_id' : status,
                'document_id' : this.document.id,
            };
            this.$axios.post("/delete_status",data)
                .then(() => {
                    this.onCheckHistory();
                    this.$store.dispatch('isLoadingOff'); 
                })
                .catch(() => {
                    this.$store.dispatch('isLoadingOff');  
                })
        }

    }
}
</script>
<style lang="scss" scoped>

.font20{
    font-size: 20px;
}
.step-circle{
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentProperties {
    .ml-5{
        margin-left: 4rem !important;
    }
    .border-step {
        border-right: 3px solid;
    }   
    .step-content{
        min-height: 30px;
        padding-left: 50px !important;
    }
}
.icon-gray{
    color: #a5a5a5;
    font-size: 30px;
}
.form-group.no-padding{
    margin-bottom: 0 !important;
}
</style>